<template>
  <div class="b-home p-3 pb-5">
    <div class="bd-info bd-card rounded-3 bg-light mb-3">
      <div class="bd-card-title p-3 d-flex align-items-center">
        <b-icon icon="info" class="text-primary mr-2" /> Info Utama
      </div>
      <div class="bd-card-content">
        <div
          class="d-flex justify-content-center align-items-center flex-column p-5"
          v-if="info.loading"
        >
          <b-spinner
            class="f-12"
            type="grow"
            label="Spinning"
            style="background-color: #159895"
          ></b-spinner>
          <span class="f-11 mt-1 bold">Loading....</span>
        </div>
        <b-carousel
          id="bd-info-carousel"
          fade
          :interval="4000"
          indicators
          background="#ababab"
          style="text-shadow: 1px 1px 2px #333"
          v-else
        >
          <b-carousel-slide
            v-for="(i, iIndex) in info.data"
            :key="`bic-${iIndex}`"
            :caption="i.judul"
          >
            <template #img>
              <router-link :to="`/info/${i.id}`">
                <img :src="i.gambar" alt="" />
              </router-link>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
    <div class="bd-info bd-card rounded-3 bg-light">
      <div class="bd-card-title p-3 d-flex align-items-center">
        <b-icon icon="info" class="text-primary mr-2" /> Daftar Info
      </div>
      <div class="bd-card-content">
        <div
          class="d-flex justify-content-center align-items-center flex-column p-5"
          v-if="info.loading"
        >
          <b-spinner
            class="f-12"
            type="grow"
            label="Spinning"
            style="background-color: #159895"
          ></b-spinner>
          <span class="f-11 mt-1 bold">Loading....</span>
        </div>
        <div v-else>
          <router-link
            :to="`/info/${i.id}`"
            class="bd-info-item p-2 border-bottom d-flex"
            v-for="(i, iIndex) in info.data"
            :key="`bii-${iIndex}`"
          >
            <div class="bdii-image">
              <img :src="i.gambar" alt="" />
            </div>
            <div class="bdii-content pl-2">
              <h5 class="text-dark">{{ i.judul }}</h5>
              <span class="text-dark">{{ dateFormat(i.created_date) }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data: () => ({
    info: {
      data: [],
      loading: false,
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getInfo() {
      this.info.loading = true;
      let url = `/information/info?nik=${this.user.data.nik}`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      if (req.data.status) {
        this.info.data = req.data.msg;
        this.info.loading = false;
      } else {
        this.info.loading = false;
      }
    },
    dateFormat(date) {
      let theDate = new Date(date);
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      let day = theDate.getDay();
      let d = theDate.getDate();
      let m = theDate.getMonth() + 1;
      let y = theDate.getFullYear();
      return `${days[day]}, ${d}-${m}-${y}`;
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>
